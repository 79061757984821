import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Typography, Divider } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Spacer from "components/spacer"
import Textarea from "components/textarea"
import SidebarContent from "components/sidebarContent"
import BackgroundImage from "components/backgroundImage"
import NewsletterModule from "components/flexibleContent/NewsletterModule"

import * as theme from "theme"
import { getUrlPath } from "utils/getUrlPath"

const PostSingle = (props) => {
  const {
    data: {
      wpPost: {
        id,
        title,
        content,
        uri,
        visibleForm: { visibleForm },
        post: { thumbnailId }
      },
      allWpPost
    }
  } = props

  const renderPost = (index) => {
    const post = allWpPost?.nodes?.[index]

    return (
      <Post to={getUrlPath(post.uri)}>
        {post?.post?.thumbnailId && (
          <BackgroundImageContainer>
            <BackgroundImage image={post.post.thumbnailId} alt={post.post.thumbnailId.altText} />
          </BackgroundImageContainer>
        )}
        <Typography variant="h6" children={Parser(post.title)} />
      </Post>
    )
  }

  let prevPost, nextPost

  const index = allWpPost?.nodes?.map((o) => o.id).indexOf(id)

  if (index === 0) {
    prevPost = allWpPost?.nodes?.length - 1
    nextPost = index + 1
  } else if (index === allWpPost?.nodes?.length - 1) {
    prevPost = index - 1
    nextPost = 0
  } else {
    prevPost = index - 1
    nextPost = index + 1
  }

  const sidebar = (
    <>
      {/* <Title children="Related Posts" /> */}
      {/* {prevPost && renderPost(prevPost)} */}
      {/* <Divider /> */}
      {/* {nextPost && renderPost(nextPost)} */}
      <NewsletterModule
        customFormId={parseInt(visibleForm)}
        headline=""
        subheadline={
          parseInt(visibleForm) === 50
            ? "Sign up now for our DIY Project Newsletter"
            : "Sign up now for our Architect/Builder Newsletter"
        }
        sidebarForm={true}
      />
    </>
  )

  return (
    <>
      <SidebarContent image={thumbnailId} title={title} slug={getUrlPath(uri)} sidebar={sidebar} reverseOrderForMobile>
        <Spacer pb={40}>
          <StyledTextarea content={content} />
        </Spacer>
      </SidebarContent>
    </>
  )
}

const StyledTextarea = styled(Textarea)`
  && {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    p {
      img {
        margin-bottom: 0;
      }
    }

    div {
      max-width: 100%;
    }

    img {
      display: flex;
      width: 100%;
    }

    figure {
      width: 100% !important;
    }
  }
`

const Title = styled(Typography)`
  display: block;
  padding: 15px 20px 0;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
  color: ${theme.colors.primary};
  text-transform: uppercase;
`

const Post = styled(Link)`
  display: block;
  text-decoration: none;
  padding: 20px;
`

const BackgroundImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  margin-bottom: 12px;
`

export const CollectionQuery = graphql`
  query DefaultSinglePost($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      uri
      content
      visibleForm {
        visibleForm
      }
      post {
        thumbnailId {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
    # allWpPost {
    #   nodes {
    #     id
    #     title
    #     uri
    #     post {
    #       thumbnailId {
    #         altText
    #         localFile {
    #           childImageSharp {
    #             gatsbyImageData(width: 600, layout: CONSTRAINED)
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`

export default PostSingle
